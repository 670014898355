export const adminHost = 'https://console.jufair.com/';
export const baiduKey = '2S3s4gNOF62azDbcLnGHf1Lunv6eoGkM';
export const baiduKey2 = '5KaZ4OvFfchHnfVcMSeDAlkKnzd2Dh04'; // 服务端 key
export const gaodeKey = '8607adb51cc446875a7a4da2f229da22';
export const bucketHost = 'jufiarbackend.oss-cn-shanghai.aliyuncs.com';
export const bucketCdnHost = 'file.jufair.com';
export const MINI_HOST = 'https://test.jufair.com';
export const OSS_BUCKET_URL = 'https://dcdn-jufair.jufair.com'; // 'https://jufair.oss-cn-shanghai.aliyuncs.com';

export const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(delay), delay);
  });
};

// 检测设备 pc电脑端 h5移动端
export const checkDevice = (): any => {
  return false; // 暂时停掉重定向检查
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return 'h5';
  } else {
    console.log('PC');
    return 'pc';
  }
};

// 获取周几/星期几
export const getWeekStr = (str: number): string => {
  let obj: any = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  };
  return obj[str];
};

// 获取今天凌晨零点时间戳
export function getMidnightTimestamp() {
  let now = new Date();
  let midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
    0
  );
  return midnight.getTime();
}

/**
 * 时间戳转日期格式
 * timestamp 时间戳
 * */
export function getCurrentTimeStr(timestamp: any) {
  let date = new Date(timestamp);
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // 月份从0开始，需要加1
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const supportsWebp = ({ createImageBitmap, Image }: any): Promise<boolean> => {
  if (!createImageBitmap || !Image) return Promise.resolve(false);

  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      createImageBitmap(image)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    };
    image.onerror = () => {
      resolve(false);
    };
    image.src =
      'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  });
};

export const webpIsSupported = (): Promise<boolean> => {
  if (process.client) {
    return supportsWebp(window);
  } else {
    return Promise.resolve(true);
  }
};

export const checkFile = (file: File, minSize = [600, 800]) => {
  const isSize = new Promise<boolean>(function (resolve) {
    const [width, height] = minSize;
    const _URL = window.URL || window.webkitURL;
    const img = new Image();
    img.onload = function () {
      const valid = img.width >= width && img.height >= height;
      // console.log(img.width, img.height, minSize);
      resolve(valid);
    };
    img.src = _URL.createObjectURL(file);
  }).catch(() => {
    return Promise.reject(new Error('尺寸大小不合适'));
  });
  return isSize;
};

interface Message {
  delay?: number;
  type: 'success' | 'info' | 'warning' | 'error';
  title?: string;
  msg?: string;
}

export const message = function (config: Message) {
  const { delay = 3000, type, title = '', msg = '' } = config;

  // @ts-ignore
  this.$confirm(msg, title, {
    showCancelButton: false,
    showConfirmButton: false,
    roundButton: true,
    type,
    center: true,
  })
    .then(() => {
      //
    })
    .catch(() => {
      //
    });
  return new Promise((resolve) => {
    // @ts-ignore
    document.querySelector('.el-message-box__btns').style.display = 'none';
    if (!msg) {
      // @ts-ignore
      document.querySelector('.el-message-box__content').style.display = 'none';
    }
    const timer = setTimeout(() => {
      const closeBtn: any = document.querySelector(
        '.el-message-box__headerbtn'
      );
      if (closeBtn) {
        closeBtn.click();
      }
      clearTimeout(timer);
      resolve(timer);
    }, delay);
  });
};

// 匹配有data-jufair属性的标签: <([a-z][a-z0-9]*)(?=.*?data-jufair="([^"]*)")
// 匹配有data-jufair属性的标签(包含到结束标签): <([a-z][a-z0-9]*)(?=.*?data-jufair="([^"]*)").+<\/\1>

// 删除空标签  如: <div></div>
export function removeEmptyTags(str: string) {
  // 定义正则表达式匹配空标签和只包含<br>元素的标签
  const emptyTagRegex =
    /<(?!img\b)([\w-]+)[^>]*>(\s*|<br\s*\/?\s*>|\n|\r|&nbsp;)*<\/\1>/g;

  // 如果字符串中仍然存在空标签或只包含<br>元素的标签
  while (emptyTagRegex.test(str)) {
    // 用正则表达式替换为空字符串
    str = str.replace(emptyTagRegex, '');
    // 递归删除子元素中的空标签
    str = removeEmptyTags(str);
  }

  return str;
}

// 替换富文本标签
const strReplace = (data: string) => {
  try {
    const html = data
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/<(?!img\b)(\w+)\s+[^>]*>/gi, '<$1>')
      .replace(/<a>/gi, '')
      .replace(/<\/a>/gi, '')
      .replace(/<mp.*?<\/mp.*?>/gi, '')
      .replace(/<svg.*?<\/svg.*?>/gi, '')
      .replace(/<iframe.*?<\/iframe.*?>/gi, '')
      .replace(/<xml>[^]*?<\/xml>/gi, '')
      .replace(/<style>[^]*?<\/style>/gi, '')
      .replace(/<spanyes.*?<\/spanyes.*?>/gi, '')
      .replace(/<spanyes.*?"="">/gi, '')
      .replace(/<\/spanyes.*?>/gi, '')
      .replace(/<o:p>.*?<\/o:p>/gi, '')
      .replace(/<h1>/gi, '</p>')
      .replace(/<\/h1>/gi, '</p>')
      .replace(/<h2>/gi, '<p>')
      .replace(/<\/h2>/gi, '</p>')
      .replace(/<h3>/gi, '<strong>')
      .replace(/<\/h3>/gi, '</strong>')
      .replace(/<h4>/gi, '<strong>')
      .replace(/<\/h4>/gi, '</strong>')
      .replace(/<b>/gi, '<strong>')
      .replace(/<\/b>/gi, '</strong>')
      .replace(/&lt;xml&gt;[^]*?&lt;\/xml&gt;/gi, '')
      .replace(/&lt;style&gt;[^]*?&lt;\/style&gt;/gi, '')
      .replace(/&lt;o:p&gt;.*?&lt;\/o:p&gt;/gi, '')
      .replace(/&lt;spanyes.*?&lt;\/spanyes.*?&gt;/gi, '')
      .replace(/&lt;spanyes.*?"=""&gt;/gi, '')
      .replace(/&lt;\/spanyes.*?&gt;/gi, '')
      .replace(/(1[3-9]\d{9})|(\d{3,4}-\d{7,8}(-\d{1,6})?)/gi, '')
      .replace(/<img[^>]*src="data:image\/svg\+xml[^"]*"[^>]*>/gi, '')
      .replace(
        /(?:https?:\/\/)?(?:\w+\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gi,
        (match, p1) => {
          if (
            !/\d+\..?/.test(match) &&
            !match.includes('jufair') &&
            !match.includes('jufiar')
          ) {
            return '';
          }
          return match;
        }
      )
      .replace(/<\w+>[<br/?>]?<\/\w+>/gi, '')
      .replace(/<img([^>]*)\b_src="[^"]*"/gi, '<img$1')
      // .replace(/<img([^>]*)src=/gi, '<img$1data-src=')
      .replace(bucketHost, bucketCdnHost);

    return html;
  } catch (e) {
    return data;
  }
};

export const getContent = (data: string) => {
  if (!data) return data;
  try {
    return decodeURIComponent(strReplace(data));
  } catch (error) {
    if (process.client && 'fundebug' in window) {
      let str = error + 'getContent__参数:';
      // window.fundebug.notifyError(str); // decodeURIComponent方法%无法解码
    }
    // console.error(id, error);
    return strReplace(data);
  }
};

/**
 * 处理资讯内容
 * 资讯的图片src属性改为data-src(懒加载需要)
 * 如果图片域名包含jufair.com, 给图片后缀加!swebp(图片压缩)
 *
 * */
export const handleNewDetail = (data: string) => {
  let result = data
    .replace(/src=/gi, 'data-src=')
    .replace(/(<img.*?data-src=")(.*?)(".*?>)/gi, function (a, b, c, d) {
      //a是整体
      //b是第一个分组  <img data-src="
      //c是第二个分组  图片url
      //d是第三个分组  ">
      // 增加图片压缩后缀 !swebp
      if (c.includes('jufair.com') && !c.includes('!swebp')) {
        return `${b}${c}!swebp${d}`;
      }
      return a;
    });
  // console.log('284-', result);
  return result;
};

// form 表单邮箱验证
export const checkEmail = (
  _rule: any,
  value: string,
  callback: (msg?: string) => void
) => {
  const mailReg = /^\S+@\S+\.+[a-z]{2,}$/;
  if (!mailReg.test(value)) {
    callback('请输入正确的邮箱.');
  } else {
    callback();
  }
};

/*
 * JSON数组去重
 * @param: [array] json Array
 * @param: [string] 唯一的key名，根据此键名进行去重
 */
export function uniqueArray(array: any[], key: string): any[] {
  const result = [array[0]];
  for (let i = 1; i < array.length; i++) {
    const item = array[i];
    let repeat = false;
    for (let j = 0; j < result.length; j++) {
      if (item[key] == result[j][key]) {
        repeat = true;
        break;
      }
    }
    if (!repeat) {
      result.push(item);
    }
  }
  return result;
}

export function formatCover(value: string) {
  let parse: any = value;
  if (typeof value === 'string') {
    try {
      parse = JSON.parse(value)[0]
        .replace('http://', 'https://')
        .replace(bucketHost, bucketCdnHost);
    } catch (error) {
      parse = value.replace('http://', 'https://');
      // console.error(error);
    }
  }
  return parse;
}

/**
 * 自定义函数名：PrefixZero
 * @param num： 被操作数
 * @param n： 固定的总位数
 */
export function prefixZero(num: number, n: number) {
  if (`${num}`.length > n) n = `${num}`.length;
  // @ts-ignore
  return (Array(n).join(0) + num).slice(-n);
}

/**
 * 秒数转为"时分秒"
 * 如: 7200秒 结果:[02, 00, 00]
 * @param {any} time:number
 * @returns {any}
 */
export function getTime(time: number) {
  // 转换为式分秒
  let h: any = parseInt(((time / 60 / 60) % 24) as any);
  h = h < 10 ? '0' + h : h;
  let m: any = parseInt(((time / 60) % 60) as any);
  m = m < 10 ? '0' + m : m;
  let s: any = parseInt((time % 60) as any);
  s = s < 10 ? '0' + s : s;
  // 作为返回值返回
  return [String(h), String(m), String(s)];
}

// 获取今天的日期
export const getTodayDate = () => {
  const today = new Date();
  let year = today.getFullYear(); // 获取当前年份
  let month = today.getMonth() + 1; // 获取当前月份（注意月份是从0开始计数的）
  let day = today.getDate(); // 获取当前日期

  return `${year}-${month}-${day}`;
};

export function getXOssProcess({
  w = 100,
  h = 100,
  watermark,
}: Record<string, any>) {
  return `?x-oss-process=image/auto-orient,1/interlace,1/resize,m_fill,w_${w},h_${h}/quality,q_100${
    watermark
      ? '/watermark,image_bG9nby9qdXpoYW4ucG5nP3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLFBfMTI,x_10,y_10'
      : ''
  }`;
}

export const decryptAndReplace = (content: string, secret: string) => {
  if (!secret) return content;
  // Base64 解密
  const decodedString = Buffer.from(secret, 'base64').toString();

  // 用下划线切割
  const parts = decodedString.split('_');
  // 十六进制转十进制
  const decimalIndexes = parts
    .slice(1)
    .map((hex) => parseInt(hex, 16))
    .sort((a: number, b: number) => a - b);

  // 替换原文中对应下标的字符为空白字符
  let originalText = content; // 请替换成实际的原文
  decimalIndexes.forEach((index, i) => {
    if (index >= 0 && index < content.length) {
      originalText =
        originalText.substring(0, index - i) +
        '' +
        originalText.substring(index - i + 1);
    }
  });

  return originalText;
};

// 在线客服
export function handleKefu(chat: string) {
  if (window.hasOwnProperty('KS')) {
    // 快商通
    (window as any).KS.openDirChatWin();
    return false;
  }
  if (window.hasOwnProperty('ysf')) {
    // 七鱼
    window.ysf('open');
    return false;
  }
  //   if (chat && (chat ?? '') !== '') {
  //     window.open(chat);
  //   }
}

// 处理视频域名替换
export function handleVideoUrl(url: string) {
  // development
  // console.log('393-', process.env.NODE_ENV);
  if (process.env.NODE_ENV !== 'production') {
    // console.log('394-仅处理生产环境');
    return url;
  }
  let urlList = [
    {
      oldUrl: 'https://dcdn-jufair.jufair.com/', // 原url
      newUrl: 'https://video2.jufair.com/', // 新url
    },
  ];
  let result = url;
  urlList.map((item: any) => {
    if (url.includes(item.oldUrl))
      result = url.replace(item.oldUrl, item.newUrl);
  });

  return result;
}

/**
 * 处理资讯url(检查资讯的viewUrl属性)
 * 如果viewUrl是null，则跳转/information/页面
 * 否则跳转viewUrl的值对应的页面
 *
 * 同时资讯详情页asyncData中检查viewUrl,
 * 如果viewUrl是null，则跳转/information/页面
 * 否则跳转viewUrl的值对应的页面
 *
 * str 资讯url
 * id 资讯id
 *
 * */
export const handleNewsUrl = (str: string, id: string | number) => {
  let url = '';
  if (str) {
    url = `${str}`;
  } else {
    url = `/information/${id}.html`;
  }
  return url;
};

// 计算页面白屏时间
export const getWhiteScreenTime = () => {
  if (window.performance) {
    // 获取performance的timing信息
    const performance = window.performance;
    // 获取unloadEventStart和fetchStart的时间戳
    const unloadTime =
      performance.timing.unloadEventStart - performance.timing.fetchStart;
    const redirectTime =
      performance.timing.redirectEnd - performance.timing.redirectStart;
    const appCacheTime =
      performance.timing.domainLookupStart - performance.timing.fetchStart;
    const dnsTime =
      performance.timing.domainLookupEnd - performance.timing.domainLookupStart; // dns解析耗时
    const tcpTime =
      performance.timing.connectEnd - performance.timing.connectStart; // tcp连接耗时
    const firstPaintTime =
      performance.timing.responseStart - performance.timing.fetchStart;

    // 计算白屏时间
    const whiteScreenTime =
      unloadTime +
      redirectTime +
      appCacheTime +
      dnsTime +
      tcpTime +
      firstPaintTime;

    // console.log(`白屏时间（ms）: ${whiteScreenTime.toFixed(0)}`);
    let res1 = performance.timing.domLoading - performance.timing.fetchStart;
    let res2 = performance.timing.responseStart - performance.timing.fetchStart; // performance.timing.responseEnd - performance.timing.fetchStart;
    let res3 = performance.timing.responseEnd - performance.timing.fetchStart;
    // console.log(res1);
    console.log(res2);
    console.log(`白屏时间（ms）: ${res2}`);
    // 页面白屏时间大于5000毫秒时，打印日志
    // if (res2 > 5000) {
    //   const fundebug = require('fundebug-javascript');
    //   fundebug.notify(
    //     '页面白屏时间',
    //     `${window.location.href} 白屏时间（ms）: ${res2}; 所有资源加载完成:${res3}`,
    //     {
    //       metaData: {
    //         url: window.location.href,
    //         whiteScreenTime: whiteScreenTime.toFixed(0),
    //       },
    //     }
    //   ); // ${whiteScreenTime.toFixed(0)}
    // }
  }
};

/**
 * 接口错误处理
 * list 错误列表
 * url 来源页面
 * */
export const handleApiError = (list: any, url: string = '/') => {
  console.log('385-接口请求失败');
  if (list.length > 0) {
    window.location.replace(
      `${window.location.origin}/system/error?redirect=${url}`
    );
  }
};

/**
 * 处理node服务错误
 * 使用场景 组件asyncData中
 * e ctx上下文
 * message 具体错误信息(包括接口状态码, 接口url)
 *
 * */
export const handleNodeServerError = (e: any, message: string) => {
  console.error(
    '服务器错误(来自：util/common.ts-handleNodeServerError):',
    message
  ); // 可在node服务控制台查看（具体报错接口url和状态码）
  return e.error({
    message: '服务器错误-请稍后重试',
    path: e.route.fullPath,
    statusCode: 504,
  });
};
